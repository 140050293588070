<template>
  <div>
    <div class="contain" v-html="content"></div>
  </div>
</template>

<script>
import { get } from "/utils/request";

export default {
  data() {
    return {
      title: "",
      content: ''
    };
  },
  watch: {
    $route(to, from) {
      console.log("to", to);
      console.log("from", from);
      this.id = to.query.id;
      this.getText(to.query.id);
    },
  },

  mounted() {
    this.getText(this.$route.query.id);
  },
  methods: {
    getText(id) {
      // 轮播图 /banner/list
      get("/banner/list", {
        enable: true,
        field: ["id", "title", "path", "url","description"],
        type: 0, // 0，网站横幅
      }).then((res) => {
        console.log(res);
        let content = ''
        res.banner.forEach(item => {
          if (item.id == id) {
            content = item.description
           }
        });
        this.content = content
      });
    },


  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 1400px;
  min-height: 442px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
}
</style>